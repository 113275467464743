/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aid-success/top-tips-for-better-communication/'], ['en', 'https://www.hear.com/resources/hearing-aid-success/top-tips-for-better-communication/'], ['en-US', 'https://www.hear.com/resources/hearing-aid-success/top-tips-for-better-communication/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aid-success/top-tips-for-better-communication/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "top-tips-for-better-communication",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#top-tips-for-better-communication",
    "aria-label": "top tips for better communication permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Top Tips For Better Communication"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether you’ve been wearing hearing aids for five days or five years, you probably have realized that while truly amazing, they aren’t able to magically cure all of your communication challenges. For example, if your partner thinks they can scream at you from another room and you can clearly understand them, we’re all in trouble. And honestly, that type of communication doesn’t work for anyone! So, what’s the secret to better communication?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Good communication combines several important elements like speaking, listening, body language, and context cues. And it’s everyone’s responsibility to be better communicators to make sure we understand each other. These important strategies go hand-in-hand with your hearing aids to boost your communication capabilities in any situation."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "when-youre-the-listener",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#when-youre-the-listener",
    "aria-label": "when youre the listener permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "When You’re the Listener"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "By taking control of the situation you’ll reduce misunderstandings and set yourself up for better communication with everyone around you."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "face-it",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#face-it",
    "aria-label": "face it permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Face It"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Always make sure you can see someone’s face and their expressions. Some research shows that as much as 80% of a message is communicated non-verbally."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Be in the same room"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Position yourself near the person speaking or in the middle of a group"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Avoid poorly lit places when possible"), "\n"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "speak-up",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#speak-up",
    "aria-label": "speak up permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Speak Up"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you don’t understand what someone has said, tell them. Don’t bluff or pretend you do."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ask them to rephrase what they said. Most times saying the same thing in a different way improves understanding."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Provide specific suggestions so the speaker can adapt. Consider saying, “I can’t listen as fast as you can talk. Would you slow down for me?” or “I’m not catching some of those words. Can you say that in a different way?”"), "\n"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "in-groups",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-groups",
    "aria-label": "in groups permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In Groups"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Position yourself in the middle so you can make eye contact with as many people as possible and encourage them to speak one at a time. If you miss parts of the conversation, it’s okay. It happens to everyone — just stay positive."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "on-the-phone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#on-the-phone",
    "aria-label": "on the phone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "On the Phone"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Try to eliminate any background noise in your house, and ask the other person to do the same. Since non-verbal cues aren’t available, remind your conversation partner to speak clearly, slowly, distinctly, but naturally."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "for-outings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#for-outings",
    "aria-label": "for outings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "For Outings"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Plan your outings ahead of time by picking the best spots for good communication. Here are some examples:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "At a restaurant, ask for a quiet table or to be seated in a corner booth away from the kitchen. And try to sit with your back toward the crowd."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Read online reviews of local eateries, which will often mention if it’s a noisier environment or good for conversation."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "If you’re going to a concert, sporting event, play, church, etc., try to get seats closer to the action."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "tips-for-family-friends-and-co-workers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tips-for-family-friends-and-co-workers",
    "aria-label": "tips for family friends and co workers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tips for Family, Friends, and Co-Workers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The best way to help people communicate better is to simply tell them how. Let them know you’re trying to be a better communicator and what you’ve learned. After all, teamwork makes the dream work! Here are some of the key ways they can help you."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "tone-and-pace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tone-and-pace",
    "aria-label": "tone and pace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tone and Pace"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Encourage them to speak clearly, slowly, distinctly, but naturally, without shouting or exaggerating mouth movements. Shouting distorts the sound of speech and may make speech reading more difficult."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "get-your-attention",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-your-attention",
    "aria-label": "get your attention permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get Your Attention"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "They can say your name, make eye contact, or wave their arms if they have to. Being able to focus your attention will reduce the chance of missing words at the beginning of the conversation."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "be-visible",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#be-visible",
    "aria-label": "be visible permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Be Visible"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Let them know it helps if you can clearly see their face when they’re talking. Ask them to keep their hands away from their face and to avoid eating, chewing, smoking, etc while talking."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "rephrase-instead-of-repeat",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rephrase-instead-of-repeat",
    "aria-label": "rephrase instead of repeat permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rephrase Instead of Repeat"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Encourage them to rephrase what they said if it’s clear you don’t understand. Saying the same thing in a different way improves communication."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "location-location-location",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#location-location-location",
    "aria-label": "location location location permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Location, Location, Location"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ask them to consider the type of environment when making plans with you outside of the home. Perhaps something a little less noisy if you prefer."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "were-here-to-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#were-here-to-help",
    "aria-label": "were here to help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We’re here to help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you made it this far, you know how important good communication is. Please don’t hesitate to let us know if you have any questions or need help. As always, we’re here for you!"), "\n", React.createElement(ButtonCta, {
    copy: "Ask your questions to a Hearing Aids Specialist",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
